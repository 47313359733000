// calculateCardCapacity.js

// Fibonacci levels mapped to capacity percentages
const fibonacciToPercentage = {
    1: 5,
    2: 10,
    3: 15,
    5: 25,
    8: 40,
    13: 75,
    21: 100
};

// Map Fibonacci values to complexity levels using ranges
const fibonacciToComplexityMultiplier = {
    low: [1, 2, 3],      // Fibonacci 1, 2, 3 → Low complexity
    medium: [5, 8],      // Fibonacci 5, 8 → Medium complexity
    high: [13, 21]       // Fibonacci 13, 21 → High complexity
};

// Function to get the complexity multiplier based on Fibonacci number
const getComplexityMultiplier = (value) => {
    if (fibonacciToComplexityMultiplier.low.includes(value)) {
        return 1.0; // Low complexity
    }
    if (fibonacciToComplexityMultiplier.medium.includes(value)) {
        return 1.25; // Medium complexity
    }
    if (fibonacciToComplexityMultiplier.high.includes(value)) {
        return 1.5; // High complexity
    }
    return 1.0; // Default to low complexity if no match
};

// Function to calculate the task-specific capacity impact
export const calculateCardCapacity = (task, columns) => {
    const currentDate = new Date();

    // Define a multiplier based on the days until the task is due
    const fibonacciMultiplier = (daysUntilDue) => {
        if (daysUntilDue <= 0) return 2.5;    // Overdue tasks, increase priority significantly
        if (daysUntilDue <= 3) return 2.0;    // Less than 3 days, very high priority
        if (daysUntilDue <= 7) return 1.75;   // Less than a week, high priority
        if (daysUntilDue <= 10) return 1.5;   // Between 7 and 10 days
        if (daysUntilDue <= 14) return 1.25;  // Between 10 and 14 days
        if (daysUntilDue <= 21) return 1.0;   // Between 14 and 21 days
        if (daysUntilDue <= 30) return 0.75;  // Between 21 and 30 days
        return 0.625;                         // More than 30 days, reduced priority
    };

    // Extract necessary cells from the task
    const effortCell = task.cells.find(cell => cell.columnId === parseInt(columns.effortColumnId));
    const dueDateCell = task.cells.find(cell => cell.columnId === parseInt(columns.dueDateColumnId));
    const complexityCell = task.cells.find(cell => cell.columnId === parseInt(columns.complexityColumnId));

    // Ensure that the complexityCell exists and has a value
    const complexity = complexityCell && complexityCell.value ? parseInt(complexityCell.value, 10) : 1;  // Default to Fibonacci 1

    // Check if all required cells are present
    if (effortCell && effortCell.value && dueDateCell && dueDateCell.value && complexityCell && complexityCell.value) {
        const difficulty = parseInt(effortCell.value, 10);
        const dueDate = new Date(dueDateCell.value); // Convert 'YYYY-MM-DD' to Date object

        // Get the complexity multiplier based on the Fibonacci value
        const complexityMultiplier = getComplexityMultiplier(complexity);

        // Check if the difficulty level has a corresponding capacity percentage
        if (fibonacciToPercentage[difficulty]) {
            let taskCapacity = fibonacciToPercentage[difficulty];

            // Calculate how far the due date is, in days
            const diffTime = dueDate - currentDate;
            const daysUntilDue = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Convert ms to days and round up

            // Get the multiplier based on how far the due date is
            const multiplier = fibonacciMultiplier(daysUntilDue);

            // Apply the due date multiplier to the task's capacity
            taskCapacity *= multiplier;

            // Apply the complexity multiplier to the task's capacity
            taskCapacity *= complexityMultiplier;

            // Round the task capacity to the nearest whole number
            taskCapacity = Math.round(taskCapacity);

            // Ensure capacity impact does not exceed 100%
            taskCapacity = taskCapacity > 100 ? 100 : taskCapacity;

            return taskCapacity;
        }
    }

    // Return 0 if any required data is missing
    return 0;
};
