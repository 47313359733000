import React, { useEffect, useState, useRef } from 'react';
import '../KanbanBoard.css';
import AUTH_BASE_URL from '../../../../util/AUTH_BASE_URL';
import KanbanCardBack from './Back';
import KanbanCardFront from './Front';
import { getGradientColors } from "../../../../util/helpers/getGradientColors";

const KanbanCard = ({ task, columns, setEditingTaskId, isEditing, isLast, fetchData, handleDeleteTask, capacity }) => {
    const [user, setUser] = useState(null);
    const cardInnerRef = useRef(null);
    const [editableFields, setEditableFields] = useState({});
    const [showOverlay, setShowOverlay] = useState(false);
    const [cachedUsers, setCachedUsers] = useState({});
    const [isHovered, setIsHovered] = useState(false);
    const columnScrollPosition = useRef(0); // To store the scroll position before editing

    const assigneeColumnId = Object.keys(columns).find(columnId => columns[columnId].title === "Assignee");
    const stateColumnId = Object.keys(columns).find(columnId => columns[columnId].title === "State");

    const assignee = editableFields[assigneeColumnId] || "Unassigned";
    const taskState = editableFields[stateColumnId]?.toLowerCase() || "";

    const getMonthsDifference = (date) => {
        const currentDate = new Date();
        const taskCreatedDate = new Date(date);
        return (currentDate.getFullYear() - taskCreatedDate.getFullYear()) * 12 + (currentDate.getMonth() - taskCreatedDate.getMonth());
    };

    // Calculate blur and opacity based on task age
    const calculateStyles = () => {
        const monthsOld = getMonthsDifference(task?.modifiedAt);
        let blurLevel = 0;
        let opacityLevel = 1;

        if (taskState === 'closed' || taskState === 'parking lot' || taskState === 'in progress') {
            return { blurLevel: 0, opacityLevel: 1 }; // No blur or opacity change for these states
        }

        if (monthsOld >= 1 && monthsOld <= 2) {
            blurLevel = 1;
            opacityLevel = 1.0;
        } else if (monthsOld > 2 && monthsOld <= 3) {
            blurLevel = 2;
            opacityLevel = 0.9;
        } else if (monthsOld > 3 && monthsOld <= 4) {
            blurLevel = 4;
            opacityLevel = 0.8;
        } else if (monthsOld > 4 && monthsOld <= 5) {
            blurLevel = 6;
            opacityLevel = 0.7;
        } else if (monthsOld > 5 && monthsOld <= 6) {
            blurLevel = 8;
            opacityLevel = 0.6;
        } else if (monthsOld > 6) {
            blurLevel = 10;
            opacityLevel = 0.5;
        }

        return { blurLevel, opacityLevel };
    };

    const { blurLevel, opacityLevel } = calculateStyles();

    // Set up the border style
    let borderStyle = {
        borderLeftColor: '#0b0b0b',
        borderWidth: '4px',
        borderStyle: 'solid',
        borderRadius: '8px'
    };

    if (taskState === 'closed' || taskState === 'parking lot') {
        borderStyle = { borderImage: 'linear-gradient(to right, grey, grey) 1' };
    } else if (taskState === 'new') {
        borderStyle = { borderImage: 'linear-gradient(to right, black, black) 1' };
    } else if (assignee) {
        const gradientColors = getGradientColors(assignee);
        borderStyle = { borderImage: `linear-gradient(${gradientColors[0]}, ${gradientColors[1]}) 1` };
    }

    // Hover handlers to remove blur and set opacity to solid when hovered
    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    // Scroll restoration logic
    const saveScrollPosition = () => {
        const columnContainer = cardInnerRef.current.closest('.kanban-column');
        if (columnContainer) {
            columnScrollPosition.current = columnContainer.scrollTop;
        }
    };

    const restoreScrollPosition = () => {
        const columnContainer = cardInnerRef.current.closest('.kanban-column');
        if (columnContainer) {
            columnContainer.scrollTo({
                top: columnScrollPosition.current,
                behavior: 'smooth',
            });
        }
    };

    const handleEdit = () => {
        saveScrollPosition(); // Save the scroll position before entering edit mode
        setShowOverlay(true);
        setEditingTaskId(task.id);
    };

    const handleSave = async () => {
        // Logic to save changes goes here
        setShowOverlay(false);
        setEditingTaskId(null);
        restoreScrollPosition(); // Restore scroll after saving
    };

    const handleCancel = () => {
        setShowOverlay(false);
        setEditingTaskId(null);
        restoreScrollPosition(); // Restore scroll after canceling
    };

    // Fetch user data when assignee changes
    useEffect(() => {
        if (assignee && !cachedUsers[assignee]) {
            const firstName = assignee.split(' ')[0];
            if (firstName === 'Unassigned') return;

            fetch(`${AUTH_BASE_URL}auth/user/name?name=${firstName}`)
                .then(response => response.json())
                .then(data => {
                    setUser(data);
                    setCachedUsers(prev => ({ ...prev, [assignee]: data }));
                })
                .catch(error => console.warn('Error fetching user:', error));
        } else if (cachedUsers[assignee]) {
            setUser(cachedUsers[assignee]);
        }
    }, [assignee, cachedUsers]);

    // Load editable fields when task is updated
    useEffect(() => {
        const fields = {};
        task?.cells.forEach(cell => {
            fields[cell.columnId] = cell.value || '';
        });
        setEditableFields(fields);
    }, [task]);

    if (!task) {
        return null;
    }

    return (
        <>
            {showOverlay && <div className="kanban-overlay" />}
            <div
                className={`kanban-card ${isEditing ? 'editing' : ''}`}
                style={{
                    ...borderStyle,
                    marginBottom: isLast ? '440px' : '0px',
                    filter: isHovered ? 'none' : `blur(${blurLevel}px)`, // Remove blur on hover
                    opacity: isHovered ? 1 : opacityLevel, // Full opacity on hover
                    transition: 'filter 0.3s ease, opacity 0.3s ease', // Smooth transitions for both
                }}
                ref={cardInnerRef}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div className="kanban-card-inner">
                    {!isEditing ? (
                        <KanbanCardFront
                            editableFields={editableFields}
                            columns={columns}
                            user={user}
                            handleEdit={handleEdit}
                            capacity={capacity}
                        />
                    ) : (
                        <KanbanCardBack
                            task={task}
                            columns={columns}
                            editableFields={editableFields}
                            handleFieldChange={(columnId, value) => setEditableFields(prev => ({ ...prev, [columnId]: value }))}
                            handleCancel={handleCancel}
                            fetchData={fetchData}
                            handleSave={handleSave}
                            handleDelete={() => handleDeleteTask(task.id)}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default KanbanCard;