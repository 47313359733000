// KanbanCardFront.js

import React from 'react';
import Avatar from '../../../global/Avatar';

const KanbanCardFront = ({ editableFields, columns, user, handleEdit, capacity }) => {
    console.log('7', capacity)
    // Extract necessary fields from editableFields
    const projectColumnId = Object.keys(columns).find(columnId => columns[columnId].title.toLowerCase() === "project");
    const descriptionColumnId = Object.keys(columns).find(columnId => columns[columnId].title.toLowerCase() === "description");
    const businessUnitColumnId = Object.keys(columns).find(columnId => columns[columnId].title.toLowerCase() === "business unit");
    const productFamilyColumnId = Object.keys(columns).find(columnId => columns[columnId].title.toLowerCase() === "product family");

    const project = editableFields[projectColumnId] || "No title provided";
    const description = editableFields[descriptionColumnId] || "No description available";
    const businessUnit = editableFields[businessUnitColumnId];
    const productFamily = editableFields[productFamilyColumnId];

    return (
        <div className="kanban-card-front">
            <h3 className="kanban-card-title">
                {project}
            </h3>

            <p className="kanban-card-description">
                {description}
            </p>

            <div className="kanban-card-footer">
                {businessUnit && (
                    <span className="kanban-card-tag">
                        {businessUnit}
                    </span>
                )}
                {user && (
                    <span className="kanban-card-assignee">
                        <Avatar user={user}/>
                    </span>
                )}
            </div>

            <div className="kanban-card-product-family mg-t-4">
                {productFamily && (
                    <span className="kanban-card-tag-small">
                        {productFamily}
                    </span>
                )}
            </div>

            {/* Capacity Display */}


            <button className='btn btn-sm edit-btn' onClick={handleEdit}>Edit</button>
            <div className="capacity-display">
                <span>Weight:</span>
                <div className="capacity-bar">
                    <div
                        className="capacity-fill"
                        style={{width: `${capacity}%`, backgroundColor: getCapacityColor(capacity)}}
                    ></div>
                </div>
                <span>{capacity}%</span>
            </div>
        </div>
    );
};

// Helper function to determine the color based on capacity
const getCapacityColor = (capacity) => {
    if (capacity > 75) return '#4caf50'; // Green
    if (capacity > 50) return '#ffeb3b'; // Yellow
    if (capacity > 25) return '#ff9800'; // Orange
    return '#f44336'; // Red
};

export default KanbanCardFront;
