// KanbanBoard.js

import React, { useEffect, useState } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import KanbanCard from './cards/KanBanCard';
import './KanbanBoard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import AUTH_BASE_URL from '../../../util/AUTH_BASE_URL';
import { calculateCardCapacity } from '../../../util/helpers/calculateCardCapacity'; // Import the calculation function

const KanbanBoard = ({ data, onDragEnd, fetchData, stateColumnId, assigneeColumnId, effortColumnId, dueDateColumnId, complexityColumnId, setData, selectedAssignee }) => {
    const [editingTaskId, setEditingTaskId] = useState(null);
    const [columns, setColumns] = useState({});
    const [columnOrder, setColumnOrder] = useState([]);
    const [newTaskId, setNewTaskId] = useState(null);
    const [loadingColumn, setLoadingColumn] = useState(null);

    useEffect(() => {
        if (data && data.columns && data.rows) {
            const dynamicColumns = {};
            data.columns.forEach((col) => {
                dynamicColumns[col.id] = { title: col.title, options: col.options, state: col.state || [] };
            });

            setColumns(dynamicColumns);

            const stateColumn = data.columns.find((col) => col.title === 'State');

            if (stateColumn && stateColumn.options) {
                setColumnOrder(stateColumn.options);
            }
        }
    }, [data]);

    const filterAndSortTasksByState = (state) => {
        const stateColId = Object.keys(columns).find(columnId => columns[columnId].title === "State");
        const assigneeColId = Object.keys(columns).find(columnId => columns[columnId].title === "Assignee");

        let tasks = data.rows.filter(row => {
            const stateCell = row.cells.find(cell => cell.columnId === parseInt(stateColId));
            return stateCell && stateCell.value === state;
        });

        return tasks;
    };

    const handleDeleteTask = async (taskId) => {
        // Optimistically remove the task from UI
        const originalData = data.rows; // Store original data in case we need to revert
        setData(prevData => {
            const updatedRows = prevData.rows.filter(task => task.id !== taskId);
            return {
                ...prevData,
                rows: updatedRows
            };
        });

        try {
            // Make the API call to delete the task from Smartsheet
            const response = await fetch(`${AUTH_BASE_URL}projects/planning/delete-task`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ taskId }),  // Pass the taskId in the request body
            });

            if (!response.ok) {
                throw new Error('Failed to delete task');
            }

            console.log('Task deleted successfully:', taskId);
        } catch (error) {
            console.error('Error deleting task:', error);

            // If there's an error, revert the UI by restoring the original data
            setData(prevData => ({
                ...prevData,
                rows: originalData
            }));
        }
    };

    const handleAddNewTask = async (columnState) => {
        setLoadingColumn(columnState);
        try {
            const response = await fetch(`${AUTH_BASE_URL}projects/planning/add-task`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    state: columnState,
                    columnId: stateColumnId,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to add new task');
            }

            const newTask = await response.json();
            setNewTaskId(newTask.id);

            await fetchData();
        } catch (error) {
            console.error('Error adding new task:', error);
        } finally {
            setLoadingColumn(null);
        }
    };

    return (
        <div className="kanban-board">
            {columnOrder.map((column, index) => (
                <Droppable droppableId={column} key={column}>
                    {(provided) => (
                        <div
                            className="kanban-column"
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            <div className="kanban-column-header">
                                <h4>{column}</h4>
                                <button
                                    className="btn btn-lg mg-r-0 pd-r-0"
                                    onClick={() => handleAddNewTask(column)}
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                </button>
                            </div>
                            <div className="kanban-tasks">
                                {loadingColumn === column && (
                                    <div className="kanban-card skeleton-card">
                                        <div className="skeleton-card-body">
                                            <div className="skeleton-line" style={{ width: '80%' }}></div>
                                            <div className="skeleton-line" style={{ width: '60%' }}></div>
                                            <div className="skeleton-line" style={{ width: '90%' }}></div>
                                        </div>
                                    </div>
                                )}
                                {filterAndSortTasksByState(column).map((task, taskIndex, tasks) => {
                                    const capacity = calculateCardCapacity(task, {
                                        effortColumnId,
                                        dueDateColumnId,
                                        complexityColumnId
                                    });

                                    return (
                                        <Draggable
                                            draggableId={String(task.id)}
                                            index={taskIndex}
                                            key={task.id}
                                            isDragDisabled={editingTaskId === task.id}
                                        >
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...(editingTaskId !== task.id && provided.dragHandleProps)}
                                                >
                                                    <KanbanCard
                                                        key={task.id}
                                                        task={task}
                                                        columns={columns}
                                                        isEditing={editingTaskId === task.id}
                                                        setEditingTaskId={setEditingTaskId}
                                                        isLast={taskIndex === tasks.length - 1}
                                                        fetchData={fetchData}
                                                        handleDeleteTask={handleDeleteTask}
                                                        selectedAssignee={selectedAssignee}
                                                        capacity={capacity} // Pass capacity as a prop
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </div>
                        </div>
                    )}
                </Droppable>
            ))}
        </div>
    );

};

export default KanbanBoard;
