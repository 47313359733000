import React, { useEffect, useState, useRef, useMemo } from 'react';
import Avatar from '../../global/Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './team.css';
import AUTH_BASE_URL from '../../../util/AUTH_BASE_URL';
import { Droppable } from 'react-beautiful-dnd';
import CapacityIcon from './CapacityIcon';
import { getGradientColors } from '../../../util/helpers/getGradientColors';
import { calculateCapacity } from "../../../util/helpers/calculateCapacity"; // Capacity calculation function

const Team = ({ assignees, selectedAssignee, onSelectAssignee }) => {
    const [users, setUsers] = useState({});
    const [cachedUsers, setCachedUsers] = useState({});
    const [teamList, setTeamList] = useState([]);
    const hasShuffled = useRef(false); // Ref to track if shuffle has been done

    // Fetch and cache user data, but don't recalculate capacity here
    useEffect(() => {
        assignees.forEach(assignee => {
            let resolvedName = assignee.name;

            // If the assignee's name is "Tony", resolve it to "Tony Lockhart"
            if (assignee.name === 'Tony') {
                resolvedName = 'Tony Lockhart';
            }

            if (!cachedUsers[resolvedName]) {
                const firstName = resolvedName.split(' ')[0];
                fetch(`${AUTH_BASE_URL}auth/user/name?name=${firstName}`)
                    .then(response => {
                        if (!response.ok) throw new Error('Network response was not ok');
                        return response.json();
                    })
                    .then(data => {
                        setUsers(prevUsers => ({
                            ...prevUsers,
                            [resolvedName]: data
                        }));
                        setCachedUsers(prev => ({ ...prev, [resolvedName]: data }));
                    })
                    .catch(error => console.warn('Error fetching user:', error));
            }
        });
    }, [assignees, cachedUsers]);

    useEffect(() => {
        const today = new Date();
        const isWednesday = today.getDay() === 4; // getDay() returns 3 for Wednesday

        if (!hasShuffled.current && assignees.length > 0 && !isWednesday) {
            const shuffledAssignees = shuffleArray(assignees);
            setTeamList(shuffledAssignees);
            hasShuffled.current = true;
        } else if (isWednesday) {
            // If it's Wednesday, just set the team list without shuffling
            setTeamList(assignees);
        }
    }, [assignees]);

    // Helper function to shuffle an array using the Fisher-Yates algorithm
    const shuffleArray = (array) => {
        const shuffled = [...array];
        for (let i = shuffled.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
        }
        return shuffled;
    };

    const handleCardClick = (assigneeName) => {
        if (assigneeName === 'Close') {
            onSelectAssignee(null);
        } else {
            onSelectAssignee(assigneeName);
        }
    };

    // Memoize the capacities for all assignees
    const capacities = useMemo(() => {
        return assignees.reduce((acc, assignee) => {
            acc[assignee.name] = calculateCapacity(assignee);
            return acc;
        }, {});
    }, [assignees]);

    const finalTeamList = selectedAssignee
        ? [...teamList, { name: 'Close', avatar: '' }]
        : teamList;

    const getColors = (capacity) => {

        if (capacity > 100) {
            return ['#81a4eb', '#0651ff']; // Red gradient for low capacity
        } else if (capacity < 60) {

            return ['#ff6b6b', '#cf3434']; // Red gradient for low capacity
        } else if (!capacity < 60 && capacity < 80) {

            return ['#efec8d', '#f9943b']; // Green gradient for high capacity
        } else {
            return ['#a7ef8d', '#3bcc0f']; // Green gradient for high capacity
        }
    };


    return (
        <div className="team-component">
            <div className="team-list">
                {finalTeamList.map(assignee => {
                    console.log('99', assignee)
                    if (assignee.name === 'Close') {
                        return (
                            <div
                                key={assignee.name}
                                style={{
                                    position: 'absolute',
                                    height: '30px',
                                    width: '30px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginBottom: '10px',
                                    cursor: 'pointer',
                                    top: '50px',
                                    right: '50px',
                                    zIndex: 1000,
                                }}
                                onClick={() => handleCardClick(assignee.name)}
                            >
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    size="1x"
                                    style={{
                                        position: 'absolute',
                                        top: '0',
                                        right: '0',
                                        padding: '5px',
                                        borderRadius: '50%',
                                        backgroundColor: 'transparent',
                                        color: '#333',
                                    }}
                                />
                            </div>
                        );
                    }

                    const [startColor, endColor] = getColors(capacities[assignee.name]);
                    const [startColorTwo, endColorTwo] = getGradientColors(assignee.name);
                    console.log(capacities)
                    return (
                        <Droppable droppableId={assignee.name} key={assignee.name}>
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    style={{
                                        backgroundColor: snapshot.isDraggingOver ? '#e0e0e0' : 'transparent',
                                        display: 'inline-block',
                                        padding: '10px',
                                        borderRadius: '10px',
                                        marginBottom: '10px',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <button
                                        key={assignee.name}
                                        className="team-card"
                                        style={{
                                            border: selectedAssignee === assignee.name
                                                ? `3px solid`
                                                : 'none',
                                            borderImage: selectedAssignee === assignee.name
                                                ? `linear-gradient(${startColorTwo}, ${endColorTwo}) 1`
                                                : 'none',
                                            cursor: 'pointer',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '120px',
                                            width: '100px',
                                            backgroundColor: selectedAssignee === assignee.name ? '#fff' : '#f9f9f9',
                                        }}
                                        onClick={() => handleCardClick(assignee.name)}
                                    >
                                        <Avatar user={users[assignee.name] || { name: assignee.name, avatar: assignee.avatar }} />
                                        <span style={{ paddingTop: '5px' }}>{assignee.name.split(' ')[0]}</span>
                                        {/* Use pre-calculated capacity */}
                                        <CapacityIcon
                                            capacity={capacities[assignee.name]}
                                            startColor={startColor}
                                            endColor={endColor}
                                        />
                                    </button>
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    );
                })}
            </div>
        </div>
    );
};

export default Team;
